@import '../../../../local/templates/megamart_default/resources/sass/system';

@import '../../../../local/templates/megamart_default/resources/sass/snippets/svg-icon-background';
@import '../../../../local/templates/megamart_default/resources/sass/blocks/forms/search-form';


.icon-svg {
    &--user {
        &:before {
            mask-image: url(/b24app/app/assets/images/icons.svg#svg-user-view);
        }
    }

    &--phone {
        &:before {
            mask-image: url(/b24app/app/assets/images/icons.svg#svg-phone-view);
        }
    }
}

.b-header-user {
	display: flex;
	align-items: center;

	&__icon {
		font-size: $h4-font-size;
		line-height: 0;
	}

	&__personal {
		line-height: 1;
	}

	&__letter,
	&__icon {
		margin: 0px map-get($spacers, 3);
	}

	@include media-breakpoint-up(lg) {
		&__personal {
			min-width: 6.5rem;
		}
	}
}


.l-head {
	position: relative;


	&__main {
		position: relative;
		z-index: 301;
    }

	&--type3 .b-header-user__icon,
	&--type3 .b-header-user__letter {
		width: 2.63rem;
		height: 2.63rem;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: map-get($spacers, 3);
    }


	@include media-breakpoint-down(sm) {
		&__main {
			display: none;
		}
	}

	@include media-breakpoint-only(md) {
		&--type3 .b-header-user__personal {
			display: none;
		}
	}
}

.landing-main {
    @extend .l-head;
}

.search-form {
    &__input {
        &:focus {
            border-color: var(--primary);
        }
    }

    &__button {
        top: 0;

        .icon-svg {
            @extend %svg-icon-background;

            color: var(--primary) !important;

            &:before {
                mask-image: url(/b24app/app/assets/images/icons.svg#svg-search-view);
            }
        }
    }
}